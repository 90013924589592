import React, { useState } from 'react'
import OptionsButton from "../../components/Common/buttons/OptionsButton";
import EwalletTiles from "../../components/Ewallet/EwalletTiles";
import EwalletTable from "../../components/Ewallet/EwalletTable";
import FundTransfer from "../../components/Ewallet/FundTransfer";
import { useTranslation } from 'react-i18next';
import Tiles from '../../components/OnellamaWallet/Tiles';
import OnellamaTables from '../../components/OnellamaWallet/OnellamaTables';
import { ApiHook } from '../../hooks/apiHook';
import PaynowOffcanvas from '../../components/OnellamaWallet/PaynowOffcanvas';

function OnellamaWallet() {
    const { t } = useTranslation();
    const tiles = ApiHook.CallonellamaWalletTiles()
    const pendingQuoteamnt = ApiHook.CallPendingAmount()
    const [currentPage, setCurrentPage] = useState(1);
    const [showPaynow, setshowPaynow] = useState(false)
    const [payNowData, setpayNowData] = useState()
    const [vehicleId, setvehicleId] = useState()
    const [payNowBtnShowStatus, setpayNowBtnShowStatus] = useState(true)
    const style = {
        position: "absolute",
        inset: "0px auto auto 0px, margin: 0px",
        transform: "translate(190px, 42px)",
    };
    const handlePaynowOffcanvas = () => {
        setshowPaynow(!showPaynow)
    }

    const payNowBTnclick = async (vehicleId) => {
        setvehicleId(vehicleId)
        const response = await pendingQuoteamnt.mutateAsync({ vehicleId })
        if (response.status) {
            setshowPaynow(true)
            setpayNowData(response?.data?.data)
        }
       
    }

    return (
        <div>
            <div className="page_head_top">{t("onellamaWallet")}</div>
            <div className="ewallet_top_btn_sec">
                {/* <div className="row justify-content-between">
                    {
                        pendingQuoteamnt?.data?.buttonShow &&
                        <div className="col-md-4 text-end">
                            <OptionsButton
                                title={"pay_now"}
                                handleOpen={handlePaynowOffcanvas}
                                style={style}
                                type={"ewallet"}
                            />
                        </div>
                    }

                </div> */}
            </div>
            {/* <Tiles
                t={t}
                tiles={tiles?.data}
            /> */}
            <OnellamaTables
                t={t}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handlePaynowOffcanvas={handlePaynowOffcanvas}
                setshowPaynow={setshowPaynow}
                payNowBTnclick={payNowBTnclick}
                setpayNowBtnShowStatus={setpayNowBtnShowStatus}
                payNowBtnShowStatus={payNowBtnShowStatus}

            />
            {
                payNowData &&
                <PaynowOffcanvas
                    show={showPaynow}
                    t={t}
                    handleClose={handlePaynowOffcanvas}
                    data={payNowData}
                    vehicleId={vehicleId}
                    setpayNowBtnShowStatus={setpayNowBtnShowStatus}
                />
            }


        </div>
    )
}

export default OnellamaWallet